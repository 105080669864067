@import url('https://fonts.googleapis.com/css?family=Julius+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cinzel+Decorative:700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display+SC&display=swap');
html {
  background-color: #1A1A1A;
  color: rgb(180, 180, 180);
  font-family: 'Julius Sans One', sans-serif;
  background-image: url('../img/background.gif');
}

header {
  top: 0;
  width: 100%;
  z-index: 10;
  opacity: 0.9;
  padding: 1.6vmax;
  position: fixed;
  text-align: center;
  background: rgba(2, 2, 2, 0.9);
}

body {
  width: 100%; 
  margin: auto;
  overflow: hidden;
  position: absolute;
  background-attachment: cover;
  background: linear-gradient(rgba(0,0,0,0), rgba(2, 2, 2, 0.9));
  animation: show 1s ease-in-out;
}

h1 {
  margin: 0;
  color: rgba(180, 180, 180, 1);
  font-family: 'Cinzel Decorative', sans-serif;
  font-size: 3.333vmax;
  text-shadow: 2px 9px 2px rgba(0, 0, 0, 1);
  text-transform: capitalize;
}

h2 {
  margin: 0;
  font-size: 2.222vmax;
  letter-spacing: 1em;
  margin-bottom: 1.2vmin;
  text-transform: capitalize;
  font-size: 1vmax !important;
  color: rgba(222, 222, 222, 0.8);
  font-family: 'Playfair Display SC', serif;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.7;
}

footer {
  bottom: 0;
  width: 100%;
  z-index: 10;
  opacity: 0.9;
  color: orange;
  position: fixed;
  padding: 1.6vmax;
  text-align: center;
  background: rgba(2, 2, 2, 0.9);
  box-shadow: inset 0px 0px 20px 0px #000000;
}

footer div {
  line-height: 0;
}

footer ul {
  margin: 0;
  padding: 0;
  border: 0;
}

footer li {
  list-style-type: none;
  display: inline;
  padding-right: 2vmax;
}

a:link {
  color: orange;
  text-decoration: none;
  font-weight: bold;
}

a:visited {
  color: orange;
}

a.important:link {
  color: #9B2E1E !important; 
  text-decoration: none;
  font-weight: bold;
}

a.important:visited {
  color: #9B2E1E;
}

a:hover {
  color: #95E0E1 !important;
  font-weight: bolder;
}

a:active {
  color: #9B2E1E;
} 

i {
  padding-right: 1vmin;
  padding-top: 2vmin;
  color: orange;
}

.highlight {
  color: rgba(255, 165, 0, 1);
}

.important {
  color: #9B2E1E !important;
}

.alternate {
  color: #95E0E1;
}

@keyframes show {
  0% { opacity: 0; }
  100% {}
}

@media screen and (max-width: 900px) {
  footer {
    font-size: 2vmax;
  }
  footer li {
    padding: 0;
    display: list-item;
  }
}